import { createRoot } from 'react-dom/client'
import './styles.css'
import { App as Canvas } from './Canvas'
import Overlay from './Overlay'
import { FixedAttributionIcon } from 'fixed-attribution-icon'

const icon = new FixedAttributionIcon('https://github.com/ektogamat')
icon.shouldHandlePositioning = false
icon.setStyles({ top: `10px`, left: `10px`, right: 'unset', bottom: 'unset' })

createRoot(document.getElementById('root')).render(
  <>
    <Canvas />
    <Overlay />
  </>
)
